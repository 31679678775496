import styled from 'styled-components';

import Cat from '../../assets/img/cat.png';
import WindowDesktop from '../../assets/img/window-desktop.svg';
import WindowMobile from '../../assets/img/window-mobile.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #f2f2f2;
  height: calc(100vh - 100px);
  width: 100%;

  @media screen and (max-width: 865px) {
    height: unset;
  }

  .mercado {
    &__section {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      height: 100%;
      width: 100%;
      max-width: 1760px;

      @media screen and (max-width: 865px) {
        flex-direction: column;
      }
    }

    &__left-side {
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 50vw;
      max-width: 580px;
      height: 100%;
      margin-right: 40px;

      @media screen and (max-width: 1840px) {
        padding-left: 80px;
      }

      @media screen and (max-width: 950px) {
        margin-right: 0px;
        padding-left: 40px;
      }

      @media screen and (max-width: 865px) {
        width: 100%;
        max-width: unset;
        margin: 0;
        padding: 0 40px;
      }

      @media screen and (max-width: 650px) {
        padding: 0 20px;
      }

      h2 {
        max-width: 100%;

        color: #343648;
        font-size: 3.75rem;
        font-weight: 700;
        line-height: 1;

        span {
          color: #ffb56c;
        }

        @media screen and (max-width: 1075px) {
          font-size: 3rem;
        }

        @media screen and (max-width: 650px) {
          font-size: 2.5rem;
        }
      }
    }

    &__subtext {
      margin-top: 30px;

      font-size: 1.5rem;
      color: #645b5b;
      text-align: justify;

      @media screen and (max-width: 1075px) {
        font-size: 1.2rem;
      }

      @media screen and (max-width: 650px) {
        font-size: 0.875rem;
      }
    }

    &__minitext {
      margin: 10px 0;

      color: #343648;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.875;

      @media screen and (max-width: 1075px) {
        font-size: 1.5rem;
      }

      @media screen and (max-width: 650px) {
        font-size: 1rem;
      }
    }

    &__right-side {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      background-image: url(${Cat});
      background-size: 1000px;
      background-repeat: no-repeat;
      background-position: top 230px right -65px;
      height: 100%;
      width: 100%;

      @media screen and (max-width: 1840px) {
        background-position: top 230px right;
        padding-right: 80px;
      }

      @media screen and (max-width: 1650px) {
        background-size: 100%;
        background-position: top 230px right -40px;
      }

      @media screen and (max-width: 1375px) {
        justify-content: flex-start;
        align-items: center;

        background-position: top 336px right -12vw;
        width: 50vw;
      }

      @media screen and (max-width: 865px) {
        background-position: left 38vw bottom;
        background-size: 420px;
        width: 100%;
        height: 70vh;
        padding: 0;
      }

      @media screen and (max-width: 680px) {
        background-position: left 32vw bottom;
      }

      @media screen and (max-width: 500px) {
        background-position: left 26vw bottom;
      }

      h3 {
        width: 194px;
        margin-right: 55px;

        color: #645b5b;
        font-size: 2.375rem;
        line-height: 0.86;
        text-align: end;

        span {
          color: #ffb56c;
        }

        @media screen and (max-width: 1760px) {
          margin-right: 50px;
        }

        @media screen and (max-width: 1375px) {
          margin-top: 28vh;
          margin-right: 0;
          transform: translateX(70px);

          width: 100px;
          font-size: 1.25rem;
        }

        @media screen and (max-width: 865px) {
          width: 100px;
          margin-top: 16vh;
          transform: translateX(30px);

          font-size: 1.25rem;
        }

        @media screen and (max-height: 568px) {
          margin-top: 13vh;
        }
      }

      h4 {
        max-width: 150px;
        margin-right: 55px;
        margin-top: 10px;

        color: #645b5b;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1;
        text-align: end;

        @media screen and (max-width: 1840px) {
          margin-right: 50px;
        }

        @media screen and (max-width: 1375px) {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;

        background-image: url(${WindowDesktop});
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center right;
        width: calc(100% - 80px);
        max-width: 975px;
        height: 100%;
        max-height: 555px;

        @media screen and (max-width: 1840px) {
          right: 80px;
        }

        @media screen and (max-width: 1375px) {
          left: 50%;
          transform: translate(-50%, 0);

          background-image: url(${WindowMobile});
          background-size: 230px 461px;
          background-position: center;
          max-height: unset;
        }
      }
    }
  }
`;
