import React from 'react';

import { Container } from './styles';

interface MarcasProps {
  id: string;
}

const Mercado: React.FC<MarcasProps> = ({ id }) => {
  return (
    <Container id={id}>
      <div className="mercado__section">
        <div className="mercado__left-side">
          <div className="mercado__minitext">mercado</div>
          <h2>
            <span>R$ 22,3 bilhões </span>de faturamento em 2019
          </h2>
          <div className="mercado__subtext">
            O Brasil tem a segunda maior população de cães, gatos e aves canoras
            e ornamentais em todo o mundo e é o terceiro maior país em população
            total de animais de estimação. Hoje, o mercado pet já representa
            0,36% do PIB brasileiro, à frente dos setores de utilidades
            domésticas e automação industrial.
          </div>
          <div className="mercado__minitext">fonte: abinpet</div>
        </div>
        <div className="mercado__right-side">
          <h3>
            139,3 milhões de pets no <span>Brasil</span>
          </h3>
          <h4>gatos, cães, peixes, aves e outros</h4>
        </div>
      </div>
    </Container>
  );
};

export default Mercado;
