import styled, { keyframes } from 'styled-components';

import AnchorLink from 'react-anchor-link-smooth-scroll';

const activeEffect = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
`;

export const Link = styled(AnchorLink)`
  position: relative;
  display: flex;

  text-decoration: none;
  color: #343648;
  font-size: 1.25rem;
  font-weight: 400;

  @media screen and (max-width: 1400px) {
    color: #ffffff;
    font-size: 2.2rem;
  }

  & + a {
    margin-left: 46px;

    @media screen and (max-width: 1400px) {
      margin-top: 24px;
      margin-left: 0;
    }
  }

  &:hover {
    font-weight: bold;
  }

  &.active {
    font-weight: bold;

    &:before {
      content: '';

      position: absolute;
      left: 0;
      bottom: -4px;

      background-color: #ffb56c;
      height: 4px;
      width: 100%;
      border-radius: 2px;

      animation: 0.4s ${activeEffect} ease-in;
    }
  }
`;
