import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff;
  height: 100vh;
  width: 100%;

  overflow-x: hidden;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }

  &:before {
    content: '';
    z-index: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100vh;

    width: 50%;
    background-color: #f0ae50;

    @media screen and (max-width: 650px) {
      content: unset;
    }
  }

  .hero {
    &__section {
      display: flex;

      width: 100%;
      max-width: 1760px;
      padding-left: 80px;

      @media screen and (max-width: 950px) {
        padding-left: 40px;
      }

      @media screen and (max-width: 650px) {
        align-items: center;

        height: 50vh;
        padding: 100px 20px;
      }
    }

    &__bg {
      z-index: 0;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-21.3%);
      flex-shrink: 0;

      max-height: 100vh;
      width: 100%;
      max-width: 114vh;

      @media screen and (max-width: 650px) {
        display: none;
      }
    }

    &__mobile-section {
      display: flex;
      justify-content: center;
      align-items: center;

      background-color: #f0ae50;
      width: 100%;
    }

    &__bg--mobile {
      flex-shrink: 0;
      display: none;
      height: 50vh;

      @media screen and (max-width: 650px) {
        display: block;
      }
    }

    &__title {
      display: flex;
      flex-direction: column;
      padding-right: 80px;

      @media screen and (max-width: 985px) {
        padding-right: 60px;
      }

      @media screen and (max-width: 650px) {
        max-width: 70%;
      }

      @media screen and (max-width: 350px) {
        max-width: 80%;
      }

      h1 {
        color: #343648;
        font-size: 5rem;
        line-height: 1;

        span {
          color: #ffb56c;
        }

        @media screen and (max-width: 1165px) {
          font-size: 4rem;
        }

        @media screen and (max-width: 985px) {
          font-size: 3rem;
        }

        @media screen and (max-width: 765px) {
          font-size: 2.2rem;
        }

        @media screen and (max-width: 350px) {
          font-size: 2rem;
        }
      }

      h3 {
        max-width: 80%;
        margin-top: 20px;

        color: #645b5b;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.3;

        @media screen and (max-width: 1020px) {
          font-size: 1.4rem;
        }

        @media screen and (max-width: 860px) {
          font-size: 1.2rem;
        }

        @media screen and (max-width: 860px) {
          max-width: 100%;
          font-size: 1rem;
        }

        @media screen and (max-width: 650px) {
          display: none;
        }
      }
    }
  }
`;
