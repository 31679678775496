import React from 'react';
import Mapa from './Mapa';
import Mensagem from './Mensagem';

import { Container } from './styles';

interface ContatoProps {
  id: string;
}

const Contato: React.FC<ContatoProps> = ({ id }) => {
  return (
    <Container id={id}>
      <Mensagem />
      <Mapa />
    </Container>
  );
};

export default Contato;
