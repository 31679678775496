import React from 'react';
import GoogleMapReact from 'google-map-react';

import { Container, Marker } from './styles';

const Mapa: React.FC = () => {
  const center = {
    lat: -23.2241961,
    lng: -45.9120853,
  };

  return (
    <Container>
      <div className="map__information">
        <h1>Informações para contato</h1>
        <div className="text-content">
          Avenida Cassiano Ricardo, 1364, <br />
          Jardim Alvorada, São José dos Campos– SP, <br />
          12240-540
        </div>
        <div className="contact-content">
          contato@petbrands.com.br
          <br />
          +55 12 99710-1333
        </div>
      </div>
      <div className="map__component">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCPpD7bjIJOOyJ0UHsDEY0dyVw5JnaDEKA' }}
          defaultCenter={center}
          defaultZoom={18}
        >
          <Marker lat={center.lat} lng={center.lng} text="Petbrands" />
        </GoogleMapReact>
      </div>
    </Container>
  );
};

export default Mapa;
