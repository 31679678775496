import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 240px;
  margin-bottom: 40px;

  @media screen and (max-width: 650px) {
    flex-direction: row;
    justify-content: unset;

    width: 100%;
    padding-right: 40px;
    padding-left: 0;
  }

  &.cell-reverse {
    @media screen and (max-width: 650px) {
      flex-direction: row-reverse;
      padding-right: 0;
      padding-left: 40px;

      .cell__image {
        margin-right: 0;
        margin-left: 20px;
      }

      .cell__text {
        text-align: end;
      }
    }
  }

  .cell {
    &__image {
      height: 90px;
      width: 90px;
      margin-bottom: 40px;

      @media screen and (max-width: 650px) {
        height: 80px;
        width: 80px;
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    &__text {
      color: #343648;
      font-size: 1.5rem;
      line-height: 1;
      text-align: center;

      @media screen and (max-width: 650px) {
        font-size: 1rem;
        text-align: unset;
      }
    }
  }
`;
