import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: #ffffff;
  height: 680px;
  padding: 100px 80px;

  @media screen and (max-width: 950px) {
    flex-direction: column;

    height: unset;
    padding: 80px 0;
  }

  .map {
    &__component {
      height: 100%;
      width: 100%;

      @media screen and (max-width: 950px) {
        height: 500px;
      }
    }

    &__information {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      width: 100%;
      max-width: 550px;
      margin-right: 60px;

      @media screen and (max-width: 950px) {
        margin-right: 0;
        margin-bottom: 40px;
        padding: 0 40px;
      }

      @media screen and (max-width: 650px) {
        padding: 0 20px;
      }

      h1 {
        margin-bottom: 16px;

        color: #343648;
        font-size: 3.75rem;
        line-height: 1;

        @media screen and (max-width: 1030px) {
          font-size: 3rem;
        }

        @media screen and (max-width: 950px) {
          max-width: 82%;
        }

        @media screen and (max-width: 850px) {
          font-size: 2.5rem;
        }

        @media screen and (max-width: 320px) {
          max-width: 100%;
        }
      }

      .text-content,
      .contact-content {
        color: #343648;
        font-size: 1.5rem;

        @media screen and (max-width: 1030px) {
          font-size: 1.2rem;
        }

        @media screen and (max-width: 850px) {
          font-size: 1rem;
        }

        @media screen and (max-width: 650px) {
          font-size: 0.875rem;
        }
      }

      .contact-content {
        margin-top: 100px;

        @media screen and (max-width: 950px) {
          margin-top: 40px;
        }
      }
    }
  }
`;

interface IMarker {
  lat: number;
  lng: number;
  text: string;
}

export const Marker = styled.div<IMarker>`
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #ea4335;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -30px 0 0 -10px;
  cursor: pointer;

  &:after {
    content: '';
    width: 14px;
    height: 14px;
    margin: 8px 0 0 8px;
    background: #a60d0d;
    position: absolute;
    border-radius: 50%;
  }

  &:before {
    content: ${({ text }) => `"${text}"`};
    color: #ea4335;
    transform: rotate(45deg);
    position: absolute;
    font-size: 1.5em;
    bottom: -16px;
    right: 0px;
    left: 30px;
  }
`;
