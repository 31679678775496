import React from 'react';
import { Link } from 'gatsby';

import { Container, Logo } from './styles';

import Extrapet from '../../assets/img/empresas/extrapet/logo.svg';
import Hospet from '../../assets/img/empresas/hospet/logo.svg';
import Performancepet from '../../assets/img/empresas/performancepet/logo.svg';
import Popularpet from '../../assets/img/empresas/popularpet/logo.svg';

import Video from '../../assets/media/video.mp4';

interface MarcasProps {
  id: string;
}

const Marcas: React.FC<MarcasProps> = ({ id }) => {
  return (
    <Container id={id}>
      <div className="marcas__video-container">
        <div className="marcas__holder">
          <h2>
            Ambiente de <span>inovação</span> e <span>criação</span>
          </h2>
        </div>
        <video src={Video} autoPlay loop muted playsInline />
        <div className="marcas__video-hover" />
      </div>
      <div className="marcas__logos-container">
        <div className="marcas__logos-holder">
          <Link to="/extrapet/">
            <Logo>
              <img src={Extrapet} alt="Logo Extrapet" />
            </Logo>
          </Link>
          <Link to="/hospet/">
            <Logo>
              <img src={Hospet} alt="Logo Hospet" />
            </Logo>
          </Link>
          <Link to="/popularpet/">
            <Logo>
              <img src={Popularpet} alt="Logo Popularpet" />
            </Logo>
          </Link>
          <Link to="/performancepet/">
            <Logo>
              <img src={Performancepet} alt="Logo Performancepet" />
            </Logo>
          </Link>
        </div>
      </div>
    </Container>
  );
};

export default Marcas;
