import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .marcas {
    &__holder {
      width: 100%;
      max-width: 1760px;
      padding: 0 80px;

      @media screen and (max-width: 950px) {
        padding-left: 40px;
      }

      @media screen and (max-width: 650px) {
        padding: 0 20px;
      }
    }

    &__video-container {
      position: relative;
      display: flex;
      align-items: center;

      height: 85vh;

      @media screen and (max-width: 650px) {
        padding-top: 140px;
        align-items: flex-start;
      }

      h2 {
        max-width: 430px;

        color: #fff;
        font-size: 3.75rem;
        font-weight: bold;
        line-height: 1;

        @media screen and (max-width: 650px) {
          max-width: 290px;

          font-size: 2.5rem;
        }

        span {
          color: #ffb56c;
        }
      }

      .marcas__content {
        max-width: 430px;
        margin-top: 16px;

        color: #ffffff;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.3;

        @media screen and (max-width: 650px) {
          display: none;
        }
      }

      video {
        z-index: -10;
        position: absolute;
        top: 0;
        left: 0;

        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .marcas__video-hover {
        z-index: -1;
        position: absolute;
        top: 0;

        background: rgba(0, 0, 0, 0.7);
        height: 100%;
        width: 100%;
      }
    }

    &__logos-container {
      position: relative;
      background-color: #f2f2f2;
      min-height: 15vh;
      width: 100%;
    }

    &__logos-holder {
      transform: translateY(-78px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;

      width: 100%;
      margin: -10px 0 0 0;
      padding: 0 80px;

      @media screen and (max-width: 1062px) {
        transform: translateY(-61.1px);
      }

      @media screen and (max-width: 650px) {
        padding: 0 20px;
        transform: translateY(-51px);
      }

      & > * {
        margin-top: 10px;
      }
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;
  background-color: #e8e8e8;
  height: 146px;
  width: 275px;
  padding: 20px 50px;

  border-radius: 8px;

  @media screen and (max-width: 1062px) {
    height: 102.2px;
    width: 192.5px;
    padding: 20px 25px;
  }

  @media screen and (max-width: 650px) {
    height: 82px;
    width: 158px;
  }

  img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);

    width: 100%;

    transition: all 0.2s ease;
  }

  &:hover {
    cursor: pointer;

    img {
      filter: none;
    }
  }
`;
