import React, { useCallback, useEffect, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';

import { Link } from './styles';

import { HeaderContainer } from '../../styles/header';

import Logo from '../../assets/img/logo.svg';

const Header: React.FC = () => {
  const [navbar, setNavbar] = useState(false);
  const [sectionPositions, setSectionPositions] = useState<
    Array<number | undefined>
  >([]);
  const [activeMenu, setActiveMenu] = useState('hero');
  const [stateMenu, setStateMenu] = useState(false);

  function changeBackground() {
    if (window.scrollY > 10) {
      setNavbar(true);
    } else setNavbar(false);
  }

  function closeResponsiveMenu() {
    setStateMenu(false);
  }

  const changeMenu = useCallback(() => {
    if (sectionPositions[4] && sectionPositions[4] - 10 <= window.scrollY) {
      setActiveMenu('contato');
    } else if (
      sectionPositions[3] &&
      sectionPositions[3] - 10 <= window.scrollY
    ) {
      setActiveMenu('sobre');
    } else if (
      sectionPositions[2] &&
      sectionPositions[2] - 110 <= window.scrollY
    ) {
      setActiveMenu('mercado');
    } else if (
      sectionPositions[1] &&
      sectionPositions[1] - 10 <= window.scrollY
    ) {
      setActiveMenu('marcas');
    } else if (
      sectionPositions[1] &&
      sectionPositions[1] - 10 > window.scrollY
    ) {
      setActiveMenu('hero');
    }
  }, [sectionPositions]);

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => window.removeEventListener('scroll', changeBackground);
  }, []);

  useEffect(() => {
    const positions = sectionPositions;
    positions[0] = document.getElementById('hero')?.offsetTop;
    positions[1] = document.getElementById('marcas')?.offsetTop;
    positions[2] = document.getElementById('mercado')?.offsetTop;
    positions[3] = document.getElementById('sobre')?.offsetTop;
    positions[4] = document.getElementById('contato')?.offsetTop;
    setSectionPositions(positions);
  }, [sectionPositions]);

  useEffect(() => {
    window.addEventListener('scroll', changeMenu);
    return () => window.removeEventListener('scroll', changeMenu);
  }, [sectionPositions, changeMenu]);

  return (
    <HeaderContainer navbarStatus={navbar} menuStatus={stateMenu}>
      <div className="header__holder">
        <GatsbyLink to="/">
          <img className="header__logo" src={Logo} alt="Logotipo Petbrands" />
        </GatsbyLink>
        <nav>
          <Link
            className={activeMenu === 'hero' ? 'active' : ''}
            href="#hero"
            onClick={() => closeResponsiveMenu()}
          >
            Início
          </Link>
          <Link
            className={activeMenu === 'marcas' ? 'active' : ''}
            href="#marcas"
            onClick={() => closeResponsiveMenu()}
          >
            Marcas
          </Link>
          <Link
            offset="100"
            className={activeMenu === 'mercado' ? 'active' : ''}
            href="#mercado"
            onClick={() => closeResponsiveMenu()}
          >
            Mercado
          </Link>
          <Link
            className={activeMenu === 'sobre' ? 'active' : ''}
            href="#sobre"
            onClick={() => closeResponsiveMenu()}
          >
            Sobre
          </Link>
          <Link
            className={activeMenu === 'contato' ? 'active' : ''}
            href="#contato"
            onClick={() => closeResponsiveMenu()}
          >
            Contato
          </Link>
        </nav>
        <div className="header__hidden" />
        <button
          type="button"
          className="header__menu-bars"
          onClick={() => setStateMenu(!stateMenu)}
        >
          <div className="menu-bars__bar menu-bars__bar--top" />
          <div className="menu-bars__bar menu-bars__bar--center" />
          <div className="menu-bars__bar menu-bars__bar--bottom" />
        </button>
      </div>
    </HeaderContainer>
  );
};

export default Header;
