import React, { FormEvent, useState } from 'react';
import emailjs from 'emailjs-com';

import apikeys from '../../../utils/apikeys';

import { Container, Input, TextArea, Button } from './styles';

const Mensagem: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageSended, setMessageSended] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm(
        apikeys.SERVICE_ID,
        apikeys.TEMPLATE_ID,
        e.target,
        apikeys.USER_ID,
      )
      .then(
        () => {
          setMessageSended(true);
        },
        () => {
          setMessageSended(true);
          setMessageError(true);

          setTimeout(() => {
            setMessageError(false);
            setMessageSended(false);
          }, 3000);
        },
      );
  };

  return (
    <Container>
      <div className="contato__left-side">
        <div className="contato__holder">
          <h1>Envie uma mensagem e tire suas dúvidas</h1>
          <div className="text-content">
            Desde cliente a investidor, estamos aqui para responder tudo a
            respeito das nossas marcas, produtos e serviços do segmento Pet
          </div>
        </div>
      </div>
      <div className="contato__right-side">
        <div className="contato__holder">
          {messageSended ? (
            <div className="contato__message-confirmation">
              {messageError ? (
                <h1>Erro ao enviar a mensagem</h1>
              ) : (
                <h1>Mensagem enviada com sucesso</h1>
              )}
              <div className="text-content">
                {messageError
                  ? 'Por favor, tente novamente!'
                  : 'Muito obrigado, logo entraremos em contato!'}
              </div>
            </div>
          ) : (
            <form name="contact_form" id="contact_form" onSubmit={handleSubmit}>
              <Input
                id="costumer_name"
                name="costumer_name"
                placeholder="Seu nome"
                value={name}
                onChange={e => setName(e.target.value)}
                type="text"
                required
              />
              <Input
                id="costumer_email"
                name="costumer_email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                required
              />
              <TextArea
                id="costumer_message"
                name="costumer_message"
                placeholder="Mensagem"
                value={message}
                onChange={e => setMessage(e.target.value)}
                required
              />
              <Button type="submit">Enviar</Button>
            </form>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Mensagem;
