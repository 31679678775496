import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

import Hero from '../compositions/Hero';
import Marcas from '../compositions/Marcas';
import Mercado from '../compositions/Mercado';
import Sobre from '../compositions/Sobre';
import Contato from '../compositions/Contato';

import Layout from '../theme/Layout';

const src: React.FC = () => {
  return (
    <Layout>
      <Header />
      <Hero id="hero" />
      <Marcas id="marcas" />
      <Mercado id="mercado" />
      <Sobre id="sobre" />
      <Contato id="contato" />
      <Footer />
    </Layout>
  );
};

export default src;
