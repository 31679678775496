import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;

  h1 {
    margin-bottom: 80px;

    color: #343648;
    font-size: 3.75rem;
    line-height: 1;

    @media screen and (max-width: 650px) {
      margin: 0 auto 80px;

      font-size: 2.5rem;
      text-align: center;
    }
  }

  .sobre {
    &__holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      height: 100%;
      padding: 100px 20px;

      @media screen and (max-width: 650px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding: 100px 20px 40px;
      }
    }

    &__cell-holder {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;

      width: 100%;

      @media screen and (max-width: 650px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
