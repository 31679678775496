import React from 'react';

import background from '../../assets/img/background.png';
import backgroundMobile from '../../assets/img/background-mobile.png';

import { Container } from './styles';

interface HeroProps {
  id: string;
}
const Hero: React.FC<HeroProps> = ({ id }) => {
  return (
    <Container id={id}>
      <div className="hero__section">
        <div className="hero__title">
          <h1>
            Vamos impactar o segmento <span>Pet</span>
          </h1>
          <h3>
            Os melhores recursos para atender suas necessidades e do seu
            amiguinho!
          </h3>
        </div>
      </div>
      <img className="hero__bg" src={background} alt="background" />
      <div className="hero__mobile-section">
        <img
          className="hero__bg--mobile"
          src={backgroundMobile}
          alt="background"
        />
      </div>
    </Container>
  );
};

export default Hero;
