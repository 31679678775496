import React from 'react';

import Head from '../components/Head';

import GlobalStyle from '../styles/global';

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <Head />
      <GlobalStyle />
      {children}
    </>
  );
};

export default Layout;
