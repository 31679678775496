import React from 'react';

import { Container } from './styles';

interface CellProps {
  className?: string;
}

const Cell: React.FC<CellProps> = ({ children, className = '' }) => {
  return <Container className={className}>{children}</Container>;
};

export default Cell;
