import styled, { keyframes } from 'styled-components';
import font from '../../../theme/variables/font';

const messageAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #ffb56c;
  height: 80vh;

  @media screen and (max-width: 850px) {
    flex-direction: column;

    height: unset;
    padding: 120px 0;
  }

  .contato {
    &__holder {
      max-width: 560px;
    }

    &__right-side {
      display: flex;
      justify-content: flex-start;

      width: 50%;
      padding-right: 80px;
      padding-left: 50px;

      @media screen and (max-width: 1030px) {
        padding-left: 25px;
      }

      @media screen and (max-width: 950px) {
        padding-right: 40px;
      }

      @media screen and (max-width: 850px) {
        justify-content: center;

        width: 100%;
        padding: 0 80px;
      }

      @media screen and (max-width: 650px) {
        padding: 0 20px;
      }
    }

    &__left-side {
      display: flex;
      justify-content: flex-end;

      width: 50%;
      padding-right: 50px;
      padding-left: 80px;

      @media screen and (max-width: 1030px) {
        padding-right: 25px;
      }

      @media screen and (max-width: 950px) {
        padding-left: 40px;
      }

      @media screen and (max-width: 850px) {
        justify-content: center;
        width: 100%;
        padding: 0 80px;
        margin-bottom: 40px;
      }

      @media screen and (max-width: 650px) {
        padding: 0 20px;
      }

      h1 {
        margin-bottom: 16px;

        color: #343648;
        font-size: 3.75rem;
        line-height: 1;
        text-align: end;

        @media screen and (max-width: 1030px) {
          font-size: 3rem;
        }

        @media screen and (max-width: 850px) {
          font-size: 2.5rem;
          text-align: start;
        }
      }

      .text-content {
        color: #343648;
        font-size: 1.5rem;
        text-align: end;

        @media screen and (max-width: 1030px) {
          font-size: 1.2rem;
        }

        @media screen and (max-width: 850px) {
          font-size: 1rem;
          text-align: start;
        }
      }
    }

    &__message-confirmation {
      display: flex;
      flex-direction: column;
      animation: 0.8s ${messageAnimation};

      img {
        height: 80px;
        width: 80px;
      }

      h1 {
        margin-bottom: 16px;

        color: #343648;
        font-size: 3.75rem;
        line-height: 1;
        text-align: center;

        @media screen and (max-width: 1030px) {
          font-size: 3rem;
        }

        @media screen and (max-width: 850px) {
          font-size: 2.5rem;
        }
      }

      .text-content {
        color: #343648;
        font-size: 1.5rem;
        text-align: center;

        @media screen and (max-width: 1030px) {
          font-size: 1.2rem;
        }

        @media screen and (max-width: 850px) {
          font-size: 1rem;
        }
      }
    }
  }
`;

export const Input = styled.input<{ value: string }>`
  opacity: ${({ value }) => (value ? 1 : 0.5)};
  background: transparent;
  width: 100%;
  padding: 6.5px 16px;
  margin-bottom: 50px;

  border-width: 0 0 1px 0;
  border-color: #343648;
  border-style: solid;

  outline: 0;

  color: #343648;
  font-family: ${font.family.poppins};
  font-size: 1.5rem;

  @media screen and (max-width: 650px) {
    margin-bottom: 40px;
    padding: 9.5px 20px;

    font-size: 1rem;
    font-weight: 500;
  }

  &:focus {
    opacity: 1;
  }

  &:-internal-autofill-selected {
    background-color: blue !important;
  }

  &::placeholder {
    color: #343648;
    font-size: 1.5rem;

    @media screen and (max-width: 650px) {
      font-size: 1rem;
      font-weight: 500;
    }
  }
`;

export const TextArea = styled.textarea<{ value: string }>`
  opacity: ${({ value }) => (value ? 1 : 0.5)};
  background: transparent;
  width: 100%;
  height: 140px;
  padding: 6.5px 16px;
  margin-bottom: 50px;

  border-width: 0 0 1px 0;
  border-color: #343648;
  border-style: solid;

  outline: 0;

  color: #343648;
  font-family: ${font.family.poppins};
  font-size: 1.5rem;

  resize: none;

  @media screen and (max-width: 650px) {
    margin-bottom: 40px;
    padding: 9.5px 20px;

    font-size: 1rem;
    font-weight: 500;
  }

  &:focus {
    opacity: 1;
  }

  &::placeholder {
    color: #343648;
    font-size: 1.5rem;

    @media screen and (max-width: 650px) {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #343648;
    border-radius: 4px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #343648;
  width: 100%;
  padding: 32px 0;
  border-radius: 6px;

  border: 0;
  outline: 0;

  color: #ffb56c;
  font-size: 2rem;
  font-weight: bold;

  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #fff;
  }
`;
