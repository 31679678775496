import React from 'react';
import Cell from './Cell';

import { Container } from './styles';

import Marketplace from '../../assets/img/icons/marketplace.svg';
import HotelBell from '../../assets/img/icons/hotel-bell.svg';
import Files from '../../assets/img/icons/files.svg';
import Shop from '../../assets/img/icons/shop.svg';
import Software from '../../assets/img/icons/software.svg';

interface SobreProps {
  id: string;
}

const Sobre: React.FC<SobreProps> = ({ id }) => {
  return (
    <Container id={id}>
      <div className="sobre__holder">
        <h1>O que fazemos</h1>
        <div className="sobre__cell-holder">
          <Cell>
            <img
              className="cell__image"
              src={Marketplace}
              alt="Icon Marketplace"
            />
            <div className="cell__text">Marketplace de produtos Pet</div>
          </Cell>
          <Cell className="cell-reverse">
            <img className="cell__image" src={HotelBell} alt="Icon HotelBell" />
            <div className="cell__text">
              Serviços de hospedagem pet e atendimento clínico veterinário
            </div>
          </Cell>
          <Cell>
            <img className="cell__image" src={Files} alt="Icon Files" />
            <div className="cell__text">
              Software de performance (B.I.) para alavancar seu negócio
            </div>
          </Cell>
          <Cell className="cell-reverse">
            <img className="cell__image" src={Shop} alt="Icon Shop" />
            <div className="cell__text">
              Ponto de venda para produtos e serviços Pet
            </div>
          </Cell>
          <Cell>
            <img className="cell__image" src={Software} alt="Icon Software" />
            <div className="cell__text">
              Software de gestão para clínicas e hospitais veterinários
            </div>
          </Cell>
        </div>
      </div>
    </Container>
  );
};

export default Sobre;
